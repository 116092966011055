
.image-sdg img.enabled-img {
    width: 100%;
    /* max-width: 111px; */
}

.image-sdg img.disabled-img{
    width: 80%;
    opacity: 0.1;
    /* max-width: 89px; */
    
}

/* @media all and (max-width: 1024px) and (min-width: 768px){
    .image-sdg img.enabled-img {
        width: 120px;
        height: 120px;
    }
    
    .image-sdg img.disabled-img{
        width: 85px;
        height: 85px;
        opacity: 0.1;
        
    }
  } */