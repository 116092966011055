.technology-item-container {
	margin-bottom: 4%;
}
.container-result-search-technology {
	display: grid;
	grid-template-columns: 75% 25%;
	text-align: center;
	max-width: var(--custom-max-width);
	margin: auto;
}
.container-result-search-technology .column {
	padding: 1%;
	padding-top: 0%;
	margin: 1%;
	margin-top: 0% !important;
}
.container-result-search-technology .column .title-filter {
	font-size: 30px;
	text-align: left;
	font-weight: 600;
	margin-top: 20%;
	margin-bottom: 15%;
}
.container-result-search-technology .link {
	background: none;
	border: none;
	text-decoration: none;
	color: #000;
	font-family: inherit;
	font-size: 18px;
	font-weight: 600;
	cursor: pointer;
	padding: 0;
	background-color: #dddddd;
	-webkit-border-radius: 70px;
	-moz-border-radius: 70px;
	border-radius: 70px;
	padding: 10px 50px;
	width: 500px;
	/* background-image: url("images/chevron-bottom.png"); */
	background-repeat: no-repeat;
	background-position: 95%;
	margin-bottom: 5%;
	margin-left: -5rem;
	margin-top: 3%;
}
.container-result-search-technology .sub-link {
	border: none;
	text-decoration: none;
	color: #5f5e5e;
	font-family: inherit;
	font-size: 18px;
	cursor: pointer;
	width: 500px;
	margin-left: -5rem;
	text-align: left;
}
.container-result-search-technology .dropdown {
	position: relative;
}
.container-result-search-technology .dropdown-menu {
	position: absolute;
	left: 0;
	top: calc(100% + 0.25rem);
	background-color: white;
	padding: 0.75rem;
	border-radius: 0.25rem;
	opacity: 0;
	pointer-events: none;
	transform: translateY(-10px);
	transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
}
.container-result-search-technology .dropdown.active > .link + .dropdown-menu {
	opacity: 1;
	transform: translateY(0);
	pointer-events: auto;
	position: initial;
}
.container-result-search-technology .dropdown.active > .link {
	/* background-image: url("images/chevron-top.png"); */
}
.container-result-search-technology .information-grid {
	display: grid;
	grid-template-columns: repeat(2, max-content);
	gap: 2rem;
}
.container-result-search-technology .dropdown-links {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
}
.container-result-search-technology p.profile-resume-investigador {
	font-size: 20px;
	text-align: left;
	color: #5f5e5e;
}
.container-result-search-technology hr {
	margin-top: 1%;
	border: solid 0.1px #bebebe;
}
.container-result-search-technology .title-tecnologia {
	font-size: var(--titleSizeTechnology);
	font-weight: 600;
	color: var(--technology-profile-color);
	text-align: left;
	margin: 0;
}
.container-result-search-technology .title-item-tecnologia {
	font-size: var(--subtitleSizeTechnology);
	font-weight: 600;
	color: #5f5e5e;
	text-align: left;
	margin: 0;
	margin-bottom: 0.4%;
}
.container-result-search-technology .text-item-tecnologia {
	font-size: var(--text-md);
	color: #5f5e5e;
	text-align: justify;
	margin: 0;
}
.column {
	display: block;
	align-items: baseline;
	padding: 0.5rem;
	gap: 1rem;
}
.column {
	display: block;
	align-items: baseline;
	padding: 0.5rem;
	gap: 1rem;
}
.content-right-investigacion-contact .btn-contactar-articulo {
	background-color: var(--primary);
	text-decoration: none;
	font-size: 16px;
	font-weight: 600;
	color: #fff;
	padding: 1% 20%;
	-webkit-border-radius: 60px;
	-moz-border-radius: 60px;
	border-radius: 60px;
	margin-left: 5%;
}
.content-contactar-articulo {
	height: 170px;
	display: flex;
	padding-top: 1%;
	border: 0.5px solid #bebebe;
	align-items: center;
}

.content-contactar-articulo .display-block-div{
	display: block;
	margin: 0px auto;
	width: 100%;

}
.content-right-investigacion-contact .title-lineas-investigacion {
	font-size: 16px;
	font-weight: 600;
	line-height: 1.3;
	width: 88%;
	text-align: left;
	margin: auto;
	color: #5f5e5e;
	text-align: center;
	margin-bottom: 15px;
}

.inline-arrow{
	display: flex;
	align-items: center;
}
@media screen and (max-width: 900px) {
	.technology-item-container {
		margin-bottom: 6%;
	}
	.container-result-search-technology .profile-investigador-tecnologia {
		margin-bottom: 4% !important;
	}
	.container-result-search-technology {
		grid-template-columns: repeat(1, 1fr);
	}
	.content-right-investigacion-contact .title-lineas-investigacion {
		margin-top: 0;
		margin-bottom: 10px;
	}
	.content-contactar-articulo {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 1%;
		margin: 1%;
	}
	.content-right-investigacion-contact .btn-contactar-articulo {
		margin: 0;
		padding: 1% 10%;
	}
}
@media (max-width: 1300px) {
	.container-result-search-technology .sub-link {
		width: 240px;
	}
	.container-result-search-technology .link {
		font-size: 16px;
		width: 14rem;
		margin-left: 0;
		background-size: 25px;
	}
	.container-result-search-technology .column .menu-item {
		padding: 0.5% 0%;
		font-size: 15px;
		min-width: 5rem;
		margin: 5px 1.5%;
	}
	.column .menu-item {
		font-size: 15px;
	}
	.container-result-search-technology .column .title-filter {
		font-size: 18px;
		text-align: center;
		margin: 12% auto 10%;
	}
}
@media (max-width: 1600px) {
	.container-result-search-technology p.profile-resume-investigador {
		margin-top: 1%;
	}
	.container-result-search-technology .sub-link {
		width: 18rem;
		margin-left: 1%;
	}
	.container-result-search-technology .column .title-filter {
		text-align: center;
	}
	.container-result-search-technology .link {
		font-size: 18px;
		width: 18rem;
		margin-left: 0;
	}
}
